import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, db, serverTimestamp, doc, updateDoc, addUserToDatabase, getUserFromDatabase, updateUserBuyCredits, updateUserSpendCredits, loginWithGoogle } from '../../firebase';

function useAuth() {

	const [signedInUser, setSignedInUser] = useState()
	const userInfo = useSelector((state) => state.auth.user)

	const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

	
	useEffect(() => {

		if(!signedInUser){
			const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
				signedIn && !currentUser && signOut()
				setSignedInUser(currentUser);
			});
			return () => {
			  unsubscribe();
			};
		}
		
	// eslint-disable-next-line
	}, []);
	
	const signInWithGoogle = async () => {

		const resp = await loginWithGoogle();
			
			if(resp.status === 'failed'){
			return {
				status: 'failed',
				message: resp.error.code
			}
			} else {
			
			const { accessToken, photoURL, email, uid, displayName } = resp.user
			
			const dbUser = await getUserFromDatabase(uid)

			if(dbUser){

				const user = doc(db, "users", uid);
				await updateDoc(user, {
					lastLogin: serverTimestamp(),
					avatar: photoURL,
					displayName: displayName,
				});

				dispatch(onSignInSuccess(accessToken))
				dispatch(setUser({
					avatar: photoURL,
					displayName: displayName,
					authority: dbUser.authority,
					email: email,
					uid:uid,
					credits: dbUser.credits
				}))

			} else {

				let user = {
					avatar: photoURL,
					displayName: displayName,
					authority: ['USER'],
					email: email,
					uid:uid,
					credits: 0
				}

				await addUserToDatabase(user)

				dispatch(onSignInSuccess(accessToken))
				dispatch(setUser(user))
			}

			
			const redirectUrl = query.get(REDIRECT_URL_KEY)
			navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
			return {
				status: 'success'
			}

			}

	}


	const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
			
			if(resp.code){

				let message = ''

				switch (resp.code) {
					case "auth/user-not-found":
						message = 'User not found.'	
					break;
					case "auth/wrong-password":
						message = 'Email/Password mismatch.'
					break;
					default:
						message = 'Something went wrong, please try again.'
				}
				return {
					status: 'failed',
					message: message
				}
			}

			if (resp.user) {
                
				const { accessToken, email, uid } = resp.user
				const dbUser = await getUserFromDatabase(uid)
				
				if(dbUser){

					const user = doc(db, "users", uid);
					await updateDoc(user, {
						lastLogin: serverTimestamp()
					});

					dispatch(onSignInSuccess(accessToken))
					dispatch(setUser({
						avatar: dbUser.photoURL || '',
						displayName: dbUser.displayName,
						authority: dbUser.authority,
						email: email,
						uid:uid
					}))
					const redirectUrl = query.get(REDIRECT_URL_KEY)
					navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
					return {
						status: 'success',
						message: ''
					}
	
					}
		

            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.message || errors.toString()
            }
        }
    }


    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)

			let message = ''

			if(resp.code){

				switch (resp.code) {
					case "auth/email-already-in-use":
						message = 'Email already registered.'
					break;
					case "auth/weak-password":
						message = 'Password is too weak, please try again.'
					break;
					default:
						message = 'Something went wrong, please try again.'
						
				}
				return {
					status: 'failed',
					message: message
				}	

			}

            if (resp.user) {
				
				const { accessToken, photoURL, email, uid } = resp.user
				const { displayFirstName, displayLastName } = values
				
				await addUserToDatabase(resp.user, { displayFirstName, displayLastName })

					dispatch(onSignInSuccess(accessToken))
					dispatch(setUser({
						avatar: photoURL || '',
						displayName: displayFirstName + ' ' + displayLastName,
						authority: ['USER'],
						email: email,
						uid:uid
					}))
					
					const redirectUrl = query.get(REDIRECT_URL_KEY)
					navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
					return {
						status: 'success',
						message: ''
					}
				}

			if(resp.err){
				return {
					status: 'failed',
					message: resp.err.message || resp.err.toString()
            }}

        } catch (errors) {
			return {
				status: 'failed',
                message: errors?.message || errors.toString()
            }
        }
    }

    const handleSignOut = ()  => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        handleSignOut()
		localStorage.removeItem('projectForm');
		await apiSignOut()
    }

	const updateUserCredits = async (uid, credits, action) => {
    	
		let updateCredits 

		try {
			
			if(action === 'buy'){
				let updateBuy = await updateUserBuyCredits(uid, credits)
				updateCredits = updateBuy.credits
			}

			if(action === 'spend'){
				let updateSpend = await updateUserSpendCredits(uid, credits)
				updateCredits = updateSpend.credits
			}
			
			if(action === 'update'){
				updateCredits = credits
			}
			
			dispatch(setUser({
				...userInfo,
				credits: updateCredits
			}))

			return {
				status: 'success',
        	}

		} catch (error) {
			return {
            status: 'failed',
            message:'Error updating user: ', error
        }
		}
    }
   
	return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
		updateUserCredits,
		signInWithGoogle,
		signedInUser
    }
}

export default useAuth